import { useState, useEffect } from "react";
import Image from "next/image";
export default function ImageSlider({ images, initialIndex = 0, onImageClick, autoplay = true, isPopup = false, content }) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isHovered, setIsHovered] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const prevSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
      if (autoplay && !isHovered) {
          const interval = setInterval(nextSlide, 3000);
          return () => clearInterval(interval);
      }
  }, [isHovered, autoplay]);

  const handleMouseOver = () => {
      if (autoplay) setIsHovered(true);
  };

  const handleMouseLeave = () => {
      if (autoplay) setIsHovered(false);
  };

  const handleArrowClick = () => {
    setIsContentVisible(true);
  };

  const handleCloseClick = () => {
    setIsContentVisible(false);
  };

  console.log("isContentVisible:", isContentVisible);
//   console.log("Images:", images);

return (
    <div className={`relative ${isPopup ? 'md:w-[605px] md:h-[473px] w-[300px] h-[300px]' : 'md:w-[352px] w-[320px] h-[280px] career-carousel-item'}`}>
      <div
        className="relative w-full h-full group"
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={() => onImageClick(images[currentIndex].src, currentIndex)}
      >
        <Image
          src={images[currentIndex]?.src || ''}
          alt={`Slider Image ${currentIndex + 1}`}
          layout="fill"
          objectFit="cover"
          className="rounded-xl transition-all duration-500 ease-in-out cursor-pointer"
        />
      </div>
      <button
        className="absolute left-0 top-1/2 transform rounded-xl hover:bg-[#1a222f] mx-1 -mt-[10px] -translate-y-1/2 bg-[#FFFFFF] bg-opacity-25 text-white p-2 group swiper-button-prev"
        onClick={prevSlide}
      >
        <span className="transition text-20 text-white swiper-button-prev-icon"></span>
      </button>
      <button
        className="absolute right-0 top-1/2 transform rounded-xl hover:bg-[#1a222f] mx-1 -mt-[10px] -translate-y-1/2 bg-[#FFFFFF] bg-opacity-25 text-white p-2 group swiper-button-next"
        onClick={nextSlide}
      >
        <span className="transition text-20 text-white swiper-button-next-icon"></span>
      </button>

      {/* Arrow Button to Slide Up the Content */}
      {content &&
      (
        <>
        {!isContentVisible &&
            <button
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2 p-2 bg-white bg-opacity-50 rounded-full z-20"
                onClick={handleArrowClick}
                >
                <span className="text-20 text-black">&#x25B2;</span> {/* Upward Arrow */}
            </button>
          }
          
    
          {/* Slide-Up Content Section */}
          <div
            className={`absolute left-0 right-0 p-4 bg-white bg-opacity-40 shadow-lg rounded-t-lg transition-transform duration-500 z-10 overflow-x-scroll ${
              isContentVisible ? 'translate-y-0' : 'translate-y-full'
            }`}
            style={{
              bottom: isContentVisible ? '0' : '-100%',
              height: '100%', // Ensure the content section covers the image
            }}
          >
            <div className="flex justify-end">
              <button onClick={handleCloseClick} className="text-black p-2">
                &times; {/* Close Icon */}
              </button>
            </div>
            <div>
              {/* Your content goes here */}
              <p className="text-black text-xs">{content}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
